<template>
  <div class="outer_page">
    <MyBreadCurmb :breadMenu="breadMenu"></MyBreadCurmb>
    <div
      class="report_content"
      :class="{ flex_column: pratiseList.length === 0 }"
    >
      <div id="main" style="width: 600px;height:400px;"></div>
      <template v-if="pratiseList.length > 0">
        <table class="table pratise_content">
          <tr class="tr">
            <th v-if="tag === 'exam'" class="th" :style="{ color: themeColor }">
              分数
            </th>
            <th class="th" :style="{ color: themeColor }">总题数</th>
            <th class="th" :style="{ color: themeColor }">已做</th>
            <th class="th" :style="{ color: themeColor }">答题日期</th>
            <th
              v-if="tag === 'reading'"
              class="th"
              :style="{ color: themeColor }"
            >
              正确率
            </th>
            <th class="th" :style="{ color: themeColor }"></th>
          </tr>
          <div class="inner_content">
            <tr
              class="tr"
              v-for="pratiseItem in pratiseList"
              :key="pratiseItem.id"
              @click="toExamDetail(pratiseItem)"
            >
              <td class="td" v-if="tag === 'exam'">
                {{ pratiseItem.score }}
              </td>
              <td class="td">{{ pratiseItem.total_question }}</td>
              <td class="td">{{ pratiseItem.finish_question }}</td>
              <td v-if="tag === 'reading'" class="td">
                {{ pratiseItem.is_right_rate }}
              </td>
              <td class="td">{{ pratiseItem.created_at }}</td>
              <td class="td">
                <span class="anticon-yyashow anticon-youjiantou"></span>
              </td>
            </tr>
          </div>
        </table>
      </template>
      <div v-else class="no_data_box">
        <no-data></no-data>
      </div>
    </div>
  </div>
</template>

<script>
import MyBreadCurmb from '../../components/BreadCrumb';
import echarts from 'echarts';
import NoData from '../../components/NoData';
import { fetchReportList, fetchReadingReportList } from '../../api/service';
import globalConfig from '@/store/global_config';
export default {
  components: { MyBreadCurmb, NoData },
  data() {
    return {
      pratiseList: [],
      breadMenu: [],
      tag: ''
    };
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    }
  },

  mounted() {
    const { chapterId, sourceId, typeId, type } = this.$route.params;
    this.bookChapaterId = chapterId;
    this.tag = type;
    this.sourceId = sourceId;
    this.typeId = typeId;
    this.fetchReportData();
    this.breadMenu = [
      {
        title: '选择教材',
        path: '/book'
      },
      {
        title: '教材详情',
        isBack: true
      },
      {
        title: '学习报告'
      }
    ];
  },

  methods: {
    // 去考试详情
    toExamDetail(item) {
      const {
        id: pId,
        book_id: bookId,
        type_id: typeId,
        book_chapter_id: chapterId
      } = item;
      const urlString = `${bookId}/${chapterId}/${typeId}/${pId}`;
      this.$router.push(`/report/detail/${this.tag}/${urlString}`);
    },

    fetchReportData() {
      if (this.tag === 'reading') {
        this.fetchReadingList();
        return;
      }
      fetchReportList({
        book_chapter_id: this.bookChapaterId,
        source_id: this.sourceId,
        type_id: this.typeId
      }).then(response => {
        const { practice_info: praticeInfo = {} } = response.data;
        this.pratiseList = praticeInfo.data || [];
        if (this.pratiseList.length === 0) {
          document.getElementById('main').style.display = 'none';
          return;
        }
        // 数组未反转
        this.showLineCanvas([...praticeInfo.data]);
      });
    },

    fetchReadingList() {
      fetchReadingReportList({
        book_chapter_id: this.bookChapaterId,
        source_id: this.sourceId,
        type_id: this.typeId
      }).then(response => {
        this.hasRequestData = true;
        const { practice_info: praticeInfo = {} } = response.data;
        this.pratiseList = praticeInfo.data || [];
        // 数组反转
        this.readingLineCanvas([...praticeInfo.data].reverse());
      });
    },

    getReadingTitle(arr) {
      const categoryArr = [];
      const scoreArr = [];
      arr.forEach((item, itemIndex) => {
        categoryArr.push(`第${itemIndex + 1}次`);
        scoreArr.push(parseFloat(item.is_right_rate));
      });
      return { categoryArr, scoreArr };
    },

    readingLineCanvas(dataArr) {
      var myChart = echarts.init(document.getElementById('main'));
      const { categoryArr, scoreArr } = this.getReadingTitle(dataArr);
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '成绩走势',
          left: 'center'
        },
        xAxis: {
          type: 'category',
          data: categoryArr
        },
        yAxis: { type: 'value', title: '正确率(百分比%)' },
        series: [
          {
            data: scoreArr.length > 0 ? scoreArr : [0],
            type: 'line'
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    // 获取横坐标数组
    getDataInnerTitle(arr) {
      const categoryArr = [];
      const scoreArr = [];
      var arrLength = arr.length;
      arr.forEach((item, itemIndex) => {
        categoryArr.push(`第${arrLength - itemIndex}次`);
        scoreArr.push(item.score);
      });
      return { categoryArr, scoreArr };
    },

    showLineCanvas(dataArr) {
      var myChart = echarts.init(document.getElementById('main'));
      const { categoryArr, scoreArr } = this.getDataInnerTitle(dataArr);
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '成绩走势',
          left: 'center'
        },
        xAxis: {
          type: 'category',
          data: categoryArr
        },
        yAxis: { type: 'value' },
        series: [
          {
            data: scoreArr,
            type: 'line'
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    }
  }
};
</script>

<style lang="less" scoped>
// 小屏
@media only screen and (max-width: 1025px) {
  .report_content {
    flex-direction: column;
    overflow-y: scroll;
  }
}

// 大屏时
@media only screen and (min-width: 1025px) {
  .report_content {
    overflow: hidden;
  }
  .pratise_content {
    display: flex;
    flex-direction: column;
    .inner_content {
      flex: 2;
      overflow-y: scroll;
    }
  }
}

.outer_page {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  .report_content {
    display: flex;
    padding: 30px;
    background-color: white;
    flex: 2;
    #main {
      margin: 0 auto;
    }
    .no_data_box {
      position: relative;
      margin-top: 40px;
    }
  }
}
</style>
